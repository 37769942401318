import React, { Component } from 'react';
import styled from 'styled-components';

const Button = styled.button`
  border: none;
  
  background-color: rgba(170, 170, 170, 0.2);
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  height: 50px;
  
  outline: none;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;

  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
  
    border: ${props => props.active ? "solid 5px rgba(61, 159, 235, 1.0)" : "solid 5px rgba(61, 159, 235, 0.0)"};
    box-shadow: 0 0 0px #fff inset;
    border-radius: 25px;

  &:hover {
    background-color: #c2e7ff;
    color: black;
  }
  &:active {
    background-color: #79c2f2;
    color: black;
  }

    @media (max-width: 900px) {
        height: 35px;
        border-width: 4px;
        padding: 5px 8px 5px 8px;
        font-size: 12px;
    }

    @media (max-width: 390px) {
        padding: 5px 5px 5px 5px;
    }

`;

class SegmentButton extends Component {

    render() {

        return (
            <Button 
                onClick={() => this.props.onFilterSelection(this.props.filter)}
                active={this.props.active}
            >
                {this.props.label}
            </Button>
          );
    }

}

export default SegmentButton;