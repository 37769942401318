import React, { Component } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';

const Content = styled.div`
    width: 100%;
`;

const Player = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
    overflow: hidden;
`;

const PlayerWrapper = styled.div`
    position: relative;
    padding-top: 50.625%; /* Player ratio: 100 / (16 / 9) * 90% width */
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
`;

const Title = styled.p`
    font-size: 16pt;
    font-weight: 500;
    text-align: center;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 20px;
    margin-top: 20px;
`;

class MediaPlayer extends Component {

    state = {
        title: '',
        activeVimeoId: ''
    }

    componentDidUpdate(oldProps) {
        const newProps = this.props;
        if (newProps.activeVimeoId !== oldProps.activeVimeoId) {
            this.updateVideo()
        }
    }

    componentDidMount() {
        this.updateVideo()
    }

    updateVideo() {
        const { activeVimeoId, title } = this.props;
        this.setState({ activeVimeoId, title }); 
    }

    render() {

        const { title, activeVimeoId } = this.props;
        const url = `http://vimeo.com/${activeVimeoId}`

        return (
            <Content>
                <PlayerWrapper>
                    <Player url={url} playing controls muted width='100%' height='100%'/>
                </PlayerWrapper>
                <Title>{title}</Title>
            </Content>
          );
    }
}

export default MediaPlayer;