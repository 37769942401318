import React, { Component } from 'react';
import styled from 'styled-components';
import MediaPlayer from './MediaPlayer';
import LockupItem from './LockupItem';
import SegmentButton from './SegmentButton';
import { ok } from 'assert';

const Content = styled.div`
  width: 100%;
  height: 100%;
`;

const MediaGrid = styled.div`
    width: 100%;
    display: inline-block;
    margin-bottom: 80px;
`;

const FilterBar = styled.div`
    width: 80%;
    justify-content: space-between;
    display: flex;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    min-width: 500px;

    @media (max-width: 900px) {
        min-width: 280px;
        width: 90%;
    }
`;

const allFilters = [
    {
        filter: null,
        label: 'All'
    },
    {
        filter: 'music video',
        label: 'Music Videos'
    },
    {
        filter: 'ad',
        label: 'Commercials'
    },
    {
        filter: 'live',
        label: 'Events'
    }
]

const vimeoBaseUrl = 'https://api.vimeo.com';
const albumsUrl = `${vimeoBaseUrl}/users/10771915/albums/5931675/videos?fields=uri,name,pictures,tags&sort=manual&per_page=30`
const authUrl = `${vimeoBaseUrl}/oauth/authorize/client`

function vimeoIdForUri(uri) {
    const extracted = uri.split('/videos/')[1];
    if (!extracted) {
        return uri.substring(8);
    }
    return extracted;
}

class MediaView extends Component {

    state = {
        filters: allFilters,
        activeVimeoId: 525900722,
        activeFilter: null,
        vimeoItems: []
    }

    onVideoSelection(vimeoId) {
        if (vimeoId) {
            const activeItem = this.state.vimeoItems.filter((item) => item.vimeoId === vimeoId)[0];
            this.setState({ activeVimeoId: vimeoId, activeTitle: activeItem.name });
            window.scroll({top: 0, left: 0, behavior: 'smooth' })
        }
    }

    onFilterSelection(filter) {
        console.log(filter);
        this.setState({ activeFilter: filter });
    }

    constructor(props) {
        super(props);
        this.onVideoSelection = this.onVideoSelection.bind(this);
        this.onFilterSelection = this.onFilterSelection.bind(this);
    }

    componentDidMount() {
        const clientId = 'ac1f504e75c1eab22cbd38a782212130c9029863';
        const clientSecret = 'BAjPurTpsH81hLso/s85WZlDjV0GaAtg90+jzWWZn0MLs90uHiLaNRNjpiVWoI7tKSIYwrR2ypRaQk3ETGjqrojcmnwAYREKNKy/o2Hl7vsXCYS8Q8vzD9ycx3TdojaM';
        const encodedKey = window.btoa(clientId + ':' + clientSecret);
        if (false) {
            fetch(authUrl, {
                method: 'POST',
                headers: {
                    'Authorization': 'basic ' + encodedKey,
                    'Content-Type': 'application/json',
                    'Accept': 'application/vnd.vimeo.*+json;version=3.4'
                },
                body: JSON.stringify({
                            "grant_type": "client_credentials",
                            "scope": "public"
                        })
            })
            .then(response => response.json())
            .then(data => {
                if (data.access_token) {
                    var accessToken = data.access_token
                    console.log("yeet em", accessToken);
                    console.log("VIMEO client RESPONSE", data)
                    this.setState({ accessToken });
                    return fetch(albumsUrl + '', {
                        headers: {
                            'Authorization': 'bearer ' + accessToken
                        }
                    })
                }
            })
            .then(response => response.json())
            .then(data => {
                console.log("VIMEO ALBUM RESPONSE", data)
                const vimeoData = data.data.map(function(item) {
                    return {
                        vimeoId: vimeoIdForUri(item.uri),
                        name: item.name,
                        image: item.pictures.sizes[item.pictures.sizes.length - 1].link,
                        tags: item.tags.map((tagItem) => tagItem.tag)
                    }
                })
                if (vimeoData) {
                    console.log("VADAYUM", vimeoData);
                    this.setState({ vimeoItems: vimeoData, activeTitle: vimeoData[0].name });
                }
            })
        }
        fetch(albumsUrl + '', {
            headers: {
                'Authorization': 'bearer ' + '1b6138cdf04a58b5e0ce7537a276607e'
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log("VIMEO ALBUM RESPONSE", data)
            const vimeoData = data.data.map(function(item) {
                return {
                    vimeoId: vimeoIdForUri(item.uri),
                    name: item.name,
                    image: item.pictures.sizes[item.pictures.sizes.length - 1].link,
                    tags: item.tags.map((tagItem) => tagItem.tag)
                }
            })
            if (vimeoData) {
                console.log("VADAYUM", vimeoData);
                this.setState({ vimeoItems: vimeoData, activeTitle: vimeoData[0].name });
            }
        })
    }

  render() {

    const { activeVimeoId, activeFilter, activeTitle, filters, vimeoItems } = this.state;

    const filterButtons = filters.map((filterData, key) =>
        <SegmentButton 
            onFilterSelection={this.onFilterSelection} 
            filter={filterData.filter}
            active={activeFilter === filterData.filter}
            label={filterData.label}
            key={key}
        />
    )

    const lockups = vimeoItems.map((item, key) =>
        <LockupItem 
            vimeoId={item.vimeoId} 
            shouldShow={(activeFilter == null) || item.tags.includes(activeFilter)}
            onVideoSelection={this.onVideoSelection} 
            selected={item.vimeoId == activeVimeoId}
            item={item}
            key={key}
        />
    );

    return (
        <Content>
            <MediaPlayer activeVimeoId={activeVimeoId} title={activeTitle}/>
            <FilterBar>
                {filterButtons}
            </FilterBar>
            <MediaGrid>
                {lockups}
            </MediaGrid>
        </Content>
      );
  }
}

export default MediaView;