import React, { Component } from 'react';
import styled from 'styled-components';
import tree from '../Assets/tree.jpg'

const Content = styled.div`
  width: 42%;
  padding: 20px;
  vertical-align: top;
  opacity:  ${props => props.shouldShow ? '1.0' : '0.2'};
  display: ${props => props.shouldShow ? 'inline-block' : 'none'};

  @media (max-width: 900px) {
    width: 86%;
  }
`;

const ImageWrapper = styled.div`
    position: relative;
    padding-top: 42%; /* Player ratio: 100 / (16 / 9) * 90% width */
    width: 100%;
    margin: 0 auto;

    transform: translate(0, 0);
    transition: transform 200ms;

    &:hover {
        transform: translate(0, -2px);
    }
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    position: absolute;
    top:-10px;
    left:-10px;
    object-fit: cover;

    opacity: ${props => props.selected ? "0.5" : "1.0"};
    border: ${props => props.selected ? "6px solid rgba(61, 159, 235, 1.0)" : "6px solid rgba(61, 159, 235, 0.0)"};
    border-radius: 20px;

    transition: border 200ms;

    transition: opacity 400ms;

    &:hover {
        opacity: 0.7;
        border: ${props => props.selected ? "6px solid rgba(61, 159, 235, 0.8)" : "6px solid rgba(61, 159, 235, 0.3)"};
    }
    &:active {
        border: ${props => props.selected ? "6px solid rgba(61, 159, 235, 1.0)" : "6px solid rgba(61, 159, 235, 0.8)"};
    }
`;

const Title = styled.p`
    margin: 0 auto;
    margin-top: 15px;
    width: 80%;
`;

class LockupItem extends Component {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    onClick(event) {
        const { onVideoSelection, item } = this.props;
        if (onVideoSelection) {
            onVideoSelection(item.vimeoId);
        }
    }

    render() {
        const { item, selected, shouldShow } = this.props;

        return (
            <Content shouldShow={shouldShow} onClick={this.onClick}>
                <ImageWrapper>
                    <Image src={item.image} selected={selected}/>
                </ImageWrapper>
                <Title>{item.name}</Title>
            </Content>
          );
    }
}

export default LockupItem;