import React, { Component } from 'react';
import styled from 'styled-components';
import logo from '../Assets/logo.png';
import animatedLogo from '../Assets/animatedlogo.gif';
import animatedLogoDark from '../Assets/animatedlogodark.gif';
import location from '../Assets/location.png';
import camera from '../Assets/camera.png';
import person from '../Assets/person.png';

const Content = styled.div`
  width: 350px;
  height: 430px;
  display: flex;
  margin: 0 auto;
  padding-top: 100px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;

  @media (max-width: 900px) {
    width: 280px;
    height: 380px;
    position: relative;
    padding-top: 20px;
  }
`;

const Centered = styled.div`
    display: block;
`;

const DetailText = styled.p`
  font-size: 14pt;
`;

const DetailSubText = styled.p`
  font-size: 14pt;
  opacity: 0.5;
`;

const Logo = styled.img`
  width: 90%;
  margin-bottom: 20px;
`;

const DetailIcon = styled.img`
  height: 100%;
  width: 20px;
  position: relative;
  margin-right: 10px;
`;

const DetailSubIcon = styled.img`
  height: 100%;
  width: 20px;
  position: relative;
  margin-right: 10px;
  opacity: 0.5;
`;

const DetailRow = styled.span`
  margin-bottom: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  border: none;
  background-color:${props => props.showAbout ? 'rgba(61, 159, 235, 1.0)': 'rgba(170, 170, 170, 0.2)'};
  padding: 10px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  
  border-radius: 20px;
  display: block;
  margin: 0 auto;
  outline: none;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
  width: 75%;

  &:hover {
    background-color: ${props => props.showAbout ? '#5caff2' : '#c2e7ff'};
    color: black;
  }
  &:active {
    background-color: #79c2f2;
    color: black;
  }
`;

class InfoBar extends Component {

  state = {
    darkMode: false
  }

  componentDidMount() {
    if (window.matchMedia) {
      window.matchMedia('(prefers-color-scheme: dark)').addListener(({ matches }) => {
        this.setState({ darkMode: matches ? true : false });
      });
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.setState({ darkMode: true })
      }
    }
  }

  render() {

    const logoImage = this.state.darkMode ? animatedLogoDark : animatedLogo;

    return (
      <Content>
          <Centered>
              <Logo src={logoImage}></Logo>
              <DetailRow>
                <DetailIcon src={camera}/>
                <DetailText>Director &amp; Cinematographer</DetailText>
              </DetailRow>
              {/*
              <DetailRow>
                <DetailSubIcon src={camera}/>
                <DetailSubText>Narrative, Music Videos, Ads &amp; Comedy</DetailSubText>
              </DetailRow>
              */}
              <DetailRow>
                <DetailIcon src={location}/>
                <DetailText>Based in Los Angeles, CA</DetailText>
              </DetailRow>
              <Button onClick={this.props.onAboutClicked} showAbout={this.props.showAbout}>
                {this.props.showAbout ? 'Back' : 'About Me'}
              </Button>
          </Centered>
      </Content>
    );
  }
}

export default InfoBar;