import React, { Component } from 'react';
import styled from 'styled-components';
import avatar from '../Assets/avatar.jpg';

const Content = styled.div`
  width: 100%;
  padding-bottom: 40px;
`;

const Centered = styled.div`
    margin: 0 auto;
    width: 60%;

    @media (max-width: 1200px) and (min-width: 900px) {
        width: 80%;
    }

    @media (max-width: 800px) {
        width: 90%;
    }
`;

const Avatar = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 100px;
  text-align: right;
  
    @media (max-width: 800px) {
        margin-top: 10px;
        order: 0;
    }
`;

const HeaderContainer = styled.div`
    text-align: left;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    @media (max-width: 800px) {
        flex-direction: column;
        align-items: center;
    }
`;

const Header = styled.h1`
    margin: 0;
    @media (max-width: 800px) {
        margin-top: 10px;
        order: 1;
    }
`;

const HistoryHeader = styled.p`
    font-size: 9pt;
    opacity: 0.5;
    line-height: 18pt;
    text-align: left;
    margin-top: -5px;
    margin-bottom: 2px;
    font-weight: bold;
`;

const BodyText = styled.p`
    font-size: 12pt;
    line-height: 22pt;
    text-align: left;
`;

const HistoryText = styled.p`
    font-size: 9pt;
    opacity: 0.5;
    line-height: 18pt;
`;

const HistoryBox = styled.div`
    border-radius: 20px;
    border: 1px solid rgba(170, 170, 170, 0.2);
    padding: 0 20px 0 20px;
`;

const ExternalLink = styled.a`
    font-size: 15pt;
    text-decoration: none;
    font-weight: bold;
    color: dodgerblue;
    margin-right: 20px;
`;

const LinkBar = styled.div`
    margin-top: 40px;
    text-align: left;
`;

class AboutView extends Component {

    state = {
    }

    constructor(props) {
        super(props);
        //this.onVideoSelection = this.onVideoSelection.bind(this);
    }

  render() {

    return (
        <Content>
            <Centered>
                <HeaderContainer>
                    <Header>About</Header>

                    <Avatar src={avatar}/>
                </HeaderContainer>
                <BodyText>
                Punya Chatterjee is a filmmaker based in Los Angeles, originally from Austin. His love of making sketch comedy videos and parodies on YouTube at a young age led to him making several music videos, commercials, and comedy shorts while studying at Stanford University, honing his skills as a director, cinematographer, and editor. He was recently the cinematographer on a dramedy pilot Down Bad and wrote and directed his first short film, Chakras For Sale, a period dramedy set in the 1960s.
                
                {/*
                    Hi, I'm Punya Chatterjee. I'm an independent filmmaker based in San Francisco, California, specializing in music videos and commercials anywhere in the Bay Area.
                    <br></br>
                    <br></br>
                    As a director, cinematographer, and editor, I can help you come up with a great story and visuals for your video, or I can execute your exact vision.
                    <br></br>
                    <br></br>
                    <HistoryHeader>ORIGIN STORY</HistoryHeader>
                    <HistoryBox>
                        <HistoryText>
                        My career in video started 10 years ago, back when I was just in middle school, when I started a YouTube channel making sketch comedy and parody music videos with a focus on good production value, eventually reaching over 70,000 subscribers. 
                        <br></br>
                        In high school I started shooting commercials for local businesses, ranging from a hospital to a swim center to a car dealership.  
                        <br></br>
                        While getting my bachelor's degree from Stanford University, I shot a variety of projects for different artists, teams, and faculty, focusing in on music videos, commercials, and concert videos. 
                        <br></br>
                        I currently work at Apple on a design team that makes animations for iOS, but I'm always enthusiastic about taking on new film and video projects.
                        </HistoryText>
                    </HistoryBox>
                    
                    <br></br>
                    If you're interested in hiring me, please contact me at punya@punyavisuals.com, or send me a DM on Instagram at @punyavisuals.
                */}
                </BodyText>

                <LinkBar>
                    <ExternalLink href='https://instagram.com/punyavisuals'>Instagram</ExternalLink>
                    <ExternalLink href='https://youtube.com/geekslayer73'>YouTube</ExternalLink>
                </LinkBar>            
            </Centered>
        </Content>
      );
  }
}

export default AboutView;