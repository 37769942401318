import React, { Component } from 'react';
import styled from 'styled-components';
import InfoBar from '../Components/InfoBar';
import MediaView from '../Components/MediaView';
import AboutView from '../Components/AboutView';

const Content = styled.div`
  width: 90%;
  max-width: 1260px;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  margin-top: 50px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const MediaColumn = styled.div`
  height: 100%;
  flex: 1;
  margin-left: 40px;
  @media (max-width: 900px) {
    margin-left: 0;
  }
`;

class HomeScreen extends Component {

  state = {
    showAbout: false
  }

  onAboutClicked(event) {
    const { showAbout } = this.state;
    this.setState({ showAbout: !showAbout })
  }

  constructor(props) {
    super(props);
    this.onAboutClicked = this.onAboutClicked.bind(this);
  }

  render() {

    const { showAbout } = this.state;
    const mainView = showAbout ? <AboutView /> : <MediaView />


    return (
      <Content>
        <InfoBar onAboutClicked={this.onAboutClicked} showAbout={showAbout}/>
        <MediaColumn>
          {mainView}
        </MediaColumn>
      </Content>
    );
  }
}

export default HomeScreen;
